<template>
  <DoughnutGraphic :options="chartOptions" :id="id" :data="chartData" />
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "DoughnutChartPaymentMethod",
  components: {
    DoughnutGraphic: () => import("@/components/ui/graphs/DoughnutGraphic"),
  },
  computed: {
    chartData() {
      return {
        labels: this.$store.state.stats.lists.paymentType.map(
          (c) => c.pay_mode
        ),
        datasets: [
          {
            backgroundColor: this.$store.state.stats.lists.paymentType.map(
              (c) => this.randomColor()()
            ),
            data: this.$store.state.stats.lists.paymentType.map((c) => c.total),
          },
        ],
      };
    },
  },
  mounted() {},
  methods: {
    ...mapGetters("app", ["randomColor"]),
  },
  data() {
    return {
      id: "paymentMethodGraph",
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
};
</script>
<style></style>
